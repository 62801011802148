import { Alert, AlertColor } from '@mui/material';
import { ApiError } from '@services/ApiError';

type AlertWrapperProps = {
    apiError?: ApiError;
    severity?: AlertColor;
    message?: string | null;
};
const AlertWrapper = ({ apiError, severity = 'error', message }: AlertWrapperProps) => {
    if (apiError?.code === 'token_not_valid') return <></>;
    if (!message && !apiError?.message) return <></>;

    return <Alert severity={severity}>{message ?? apiError?.message}</Alert>;
};

export default AlertWrapper;
