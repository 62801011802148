import FieldLabel from '@components/filters/labels/FieldLabel';
import SortableColorsField from '@components/profile/SortableColorsField';
import GroupColorPickerPopover from '@components/plots/colorPicker/GroupColorPickerPopover';
import Button from '@components/Button';
import { PlusIcon } from '@heroicons/react/outline';
import { useField, useFormikContext } from 'formik';

type Props = {
    submitOnChange?: boolean;
    name?: string;
    saveText?: string;
    hidePresets?: boolean;
    leftOffsetClassName?: string;
    bottomOffsetClassname?: string;
    offsetLeft?: boolean;
    customColorsLabel?: string;
    secondaryCustomColors?: string[];
    secondaryCustomColorsLabel?: string;
};
const CustomPlotColorsField = ({
    submitOnChange = false,
    name = 'custom_plot_colors',
    saveText = 'Add to favorites',
    hidePresets = false,
    offsetLeft,
    leftOffsetClassName,
    bottomOffsetClassname,
    customColorsLabel,
    secondaryCustomColors,
    secondaryCustomColorsLabel,
}: Props) => {
    const [, meta, helpers] = useField<string[]>(name);
    const { submitForm, submitCount } = useFormikContext();
    return (
        <div className="space-y-2">
            {meta.value.length === 0 && <div className="mb-4 text-sm">No custom colors have been set up</div>}

            <div className="space-y-2">
                {meta.value.length > 0 && <FieldLabel>Hex code</FieldLabel>}
                <SortableColorsField
                    name={name}
                    onDragEnd={() => (submitOnChange ? submitForm() : undefined)}
                    className="-mx-2"
                />

                <div className="flex items-center">
                    <GroupColorPickerPopover
                        onChange={(color) => {
                            helpers.setValue([...meta.value, color]);
                            if (submitOnChange) {
                                submitForm();
                            }
                        }}
                        customColors={meta.value}
                        saveText={saveText}
                        key={`form_${submitCount}`}
                        hideThemes={hidePresets}
                        offsetLeft={offsetLeft}
                        bottomOffsetClassname={bottomOffsetClassname}
                        leftOffsetClassName={leftOffsetClassName}
                        customColorsLabel={customColorsLabel}
                        secondaryCustomColors={secondaryCustomColors}
                        secondaryCustomColorsLabel={secondaryCustomColorsLabel}
                    >
                        <Button color="primary" variant="contained" size="small" type="button">
                            <PlusIcon className="mr-1 h-4 w-4" /> Color
                        </Button>
                    </GroupColorPickerPopover>
                </div>
            </div>
        </div>
    );
};

export default CustomPlotColorsField;
