import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragStartEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { useState } from 'react';
import Logger from '@util/Logger';
import { FieldArray, useField } from 'formik';
import { TrashIcon } from '@heroicons/react/outline';
import MoreMenuIconButton from '@components/MoreMenuIconButton';

const logger = Logger.make('SortableColorsField');

const SortableColorItem = ({
    color,
    disabled,
    onDelete,
}: {
    color: string;
    disabled?: boolean;
    onDelete?: () => void;
}) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: color,
        disabled,
    });

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
        zIndex: isDragging ? 10 : undefined,
    };
    return (
        <li ref={setNodeRef} style={style} {...listeners} {...attributes}>
            <div className="group flex items-center justify-between space-x-2 rounded px-2 py-1 hover:bg-indigo-100/25">
                <div className="flex items-center space-x-2">
                    <span style={{ backgroundColor: color }} className="h-6 w-6 rounded-lg "></span>
                    <span>{color}</span>
                </div>
                <div>
                    <MoreMenuIconButton
                        size="small"
                        items={[
                            {
                                onClick: () => onDelete?.(),
                                label: <span className="text-sm text-error">Remove</span>,
                                icon: <TrashIcon width={16} className="text-error" />,
                            },
                        ]}
                    />
                </div>
            </div>
        </li>
    );
};

type Props = { name: string; onDragEnd?: () => void; className?: string };
const SortableColorsField = ({ name, onDragEnd, className }: Props) => {
    const [{ value: colors }, _meta, helpers] = useField<string[]>(name);

    const [, setActiveId] = useState<string | number | null>(null);
    const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 10 } }));

    const handleDragStart = (event: DragStartEvent) => {
        const { active } = event;
        setActiveId(active?.id);
    };

    const handleDragEnd = (event: DragEndEvent) => {
        logger.info('drag end event', event);
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = colors.findIndex((item) => item === active.id);
            const newIndex = colors.findIndex((item) => item === over?.id);

            const sorted = arrayMove(colors, oldIndex, newIndex);
            helpers.setValue(sorted);
            onDragEnd?.();
        }
        setActiveId(null);
    };

    return (
        <DndContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            sensors={sensors}
            modifiers={[restrictToParentElement]}
            collisionDetection={closestCenter}
        >
            <SortableContext items={colors}>
                <ul className={className}>
                    <FieldArray name={name}>
                        {(arrayHelpers) => (
                            <>
                                {colors.map((color, i) => (
                                    <SortableColorItem
                                        color={color}
                                        onDelete={() => {
                                            arrayHelpers.remove(i);
                                            onDragEnd?.();
                                        }}
                                        key={color}
                                    />
                                ))}
                            </>
                        )}
                    </FieldArray>
                </ul>
            </SortableContext>
        </DndContext>
    );
};

export default SortableColorsField;
