import useAuth from '@hooks/useAuth';
import useApi from '@hooks/useApi';
import Endpoints from '@services/Endpoints';
import { UserProfile } from '@models/User';
import Logger from '@util/Logger';
import { Formik } from 'formik';
import CustomPlotColorsField from '@components/profile/CustomPlotColorsField';

const logger = Logger.make('CustomColorsForm');
type FormValues = { colors: string[] };
type Props = { hidePresets?: boolean; saveText?: string; customColorsLabel?: string };
const CustomColorsForm = ({ hidePresets, saveText = 'Add color', customColorsLabel = 'My favorite colors' }: Props) => {
    const { user, revalidateUser } = useAuth();
    const api = useApi();

    const handleAddColor = async (values: FormValues) => {
        const updatedUser = await revalidateUser(async (current) => {
            const profile = await api.put<UserProfile, Partial<UserProfile>>(Endpoints.user.profile(), {
                custom_plot_colors: values.colors,
            });
            return { ...current, ...profile };
        }, false);
        logger.debug('saved colors to user', updatedUser);
    };

    return (
        <Formik onSubmit={handleAddColor} initialValues={{ colors: user?.custom_plot_colors ?? [] }} enableReinitialize>
            <CustomPlotColorsField
                name="colors"
                submitOnChange
                saveText={saveText}
                hidePresets={hidePresets}
                secondaryCustomColors={user?.organization?.custom_plot_colors ?? []}
                secondaryCustomColorsLabel="Organization favorite colors"
                customColorsLabel={customColorsLabel}
            />
        </Formik>
    );
};

export default CustomColorsForm;
